// NavigationContext.js
import { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const [previousUrl, setPreviousUrl] = useState('');
    const location = useLocation();

    useEffect(() => {
        // Mettre à jour l'URL précédente
        setPreviousUrl(prevLocation => {
            // Ne mettre à jour l'URL précédente que si l'URL actuelle change
            return location.pathname === prevLocation ? prevLocation : location.pathname;
        });
    }, [location]);

    return (
        <NavigationContext.Provider value={{ previousUrl }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useHistoryStack = () => useContext(NavigationContext);
