import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './AppContext'; // Importez AppProvider
import { CreditsProvider } from './CreditsContext';
import AdminLogin from './AdminLogin';
import AdminInterface from './AdminInterface';
import { AuthProvider } from './AuthContext';



const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <CreditsProvider>
        <AppProvider>
          <BrowserRouter>
            <App />
              <Routes>
                <Route path="/admin" element={<AdminLogin />} />
                <Route path="/adminInterface" element={<AdminInterface />} />
              </Routes>
          </BrowserRouter>
        </AppProvider>
      </CreditsProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
