import React, { useState, useRef } from 'react';
import ExcelJS from 'exceljs';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const apiUrl = process.env.REACT_APP_API_URL;

function FileUploader() {
  const [fileData, setFileData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // Ajout d'un état pour gérer les messages d'erreur

  const updateDatabase = async (data) => {
    const transformedData = data.map(item => ({
      key: uuidv4(),
      project_name: item.project_name,
      keyword: item.keyword,
      content: item.content,
      date: new Date().toLocaleString()
    }));

    try {
      const userId = localStorage.getItem('userId');

      const response = await axios.post(`${apiUrl}/ngrams/ai_articles_from_excelfile`, {
        userId,
        data: transformedData
      });

      console.log('Update successful', response.data);
    } catch (error) {
      console.error('There has been a problem with your operation:', error.response ? error.response.data : error.message);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = async (e) => {
      const buffer = e.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
  
      const worksheet = workbook.worksheets[0];
      let headers = [];
      const rows = [];
  
      worksheet.eachRow((row, rowNumber) => {
        const values = row.values.slice(1); // slice(1) pour enlever le premier élément vide du tableau des valeurs
  
        if (rowNumber === 1) {
          // Enregistrement des en-têtes pour vérification
          headers = values;
          if (headers.length !== 3 || headers[0] !== 'project_name' || headers[1] !== 'keyword' || headers[2] !== 'content') {
            setErrorMessage('Incorrect file format: Expected columns are "project_name", "keyword", "content".');
            return;
          }
        } else {
          if (values.length < 3 || !values[0] || !values[1] || !values[2]) {
            // Sauter les lignes avec des données manquantes
            return;
          }
          rows.push({ project_name: values[0], keyword: values[1], content: values[2] });
        }
      });
  
      if (rows.length === 0) {
        setErrorMessage('No valid data found in the file.');
        return;
      }
  
      if (rows.length > 1000) {
        setErrorMessage('The file exceeds the limit of 1000 lines. Please choose a smaller file.');
        return;
      }
  
      setErrorMessage(''); // Réinitialiser le message d'erreur si tout est correct
      console.log(rows);
      setFileData(rows);
      updateDatabase(rows);
    };
  
    reader.readAsArrayBuffer(file);
  };
  

  // Ref pour maintenir la référence au champ d'entrée du fichier
  const fileInputRef = React.useRef();

  const handleFileUploadClick = () => {
    // Simuler un clic sur l'input de type file
    fileInputRef.current.click();
  };

  return (
    <div>
      {/* L'input file est masqué */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        accept=".xlsx"
        style={{ display: 'none' }}
      />
      
      {/* Un bouton personnalisé qui, lorsqu'il est cliqué, déclenche l'input file caché */}
      <button onClick={handleFileUploadClick} style={{display: 'inline-flex', alignItems: 'center', backgroundColor: 'none'}}><span className="material-symbols-outlined icon" style={{ marginRight: '15px' }}>file_upload</span> Upload xlsx File</button>
      <br /><br /><br />
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

    </div>
  );
}

export default FileUploader;
