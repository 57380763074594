// CreditsContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const CreditsContext = createContext();

const apiUrl = process.env.REACT_APP_API_URL
// const apiUrl = 'http://localhost:8080';

export const useCredits = () => useContext(CreditsContext);

export const CreditsProvider = ({ children }) => {
    const [credits, setCredits] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchCredits = async () => {
            const userId = localStorage.getItem('userId'); // Assurez-vous que l'userId est stocké dans localStorage
            const timestamp = new Date().getTime(); // Générer un timestamp comme identifiant unique
        
            if (userId) {
                setIsLoading(true);
                try {
                    const response = await axios.get(`${apiUrl}/database/get_credits/${userId}?t=${timestamp}`,
                    {
                        headers: {
                            'Cache-Control': 'no-cache',
                        }
                    });
                    setCredits(response.data.credits);
                } catch (error) {
                    console.error('Erreur lors de la récupération des crédits', error);
                }
                setIsLoading(false);
            }
        };
        fetchCredits();
    }, [localStorage.getItem('userId')]);

    return (
        <CreditsContext.Provider value={{ credits, setCredits, isLoading }}>
            {children}
        </CreditsContext.Provider>
    );
};
