// SessionExpiredModal.js
function SessionExpiredModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  // Empêcher la propagation de l'événement de clic au fond sombre pour ne pas fermer le modal
  function handleModalClick(e) {
      e.stopPropagation();
  }

  return (
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.75)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }} onClick={handleModalClick}>
          <div style={{ background: 'white', padding: '20px', borderRadius: '5px', width: '300px', textAlign: 'center' }} onClick={handleModalClick}>
              <p>Your session has expired. Please log in again to continue using the application.</p>
              <button onClick={onClose} style={{ marginTop: '10px' }}>Login</button>
          </div>
      </div>
  );
}

export default SessionExpiredModal;
