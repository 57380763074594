import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = 'http://localhost:8080/';

export const useAuth = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userId, setUserId] = useState(null);
    const [showSessionExpiredAlert, setShowSessionExpiredAlert] = useState(false);

    useEffect(() => {
        let inactivityTimer;
        let warningTimer;
    
        const sessionTimeout = 14400000; // 4 heures en millisecondes 14400000
        const warningTimeout = 14370000; // Avertir 5 minutes avant la déconnexion 14370000
        const logoutDelay = 30000; // Délai de 30 secondes après l'avertissement pour effectuer la déconnexion
    
        const updateExpirationTime = () => {
            const newExpirationTime = new Date(new Date().getTime() + sessionTimeout);
            localStorage.setItem('expirationTime', newExpirationTime.toISOString());
        };
    
        const logoutAndRedirect = () => {
            logoutUser(); // Déconnectez l'utilisateur
            navigate('/login'); // Redirigez l'utilisateur vers la page de connexion
        };
    
        const showWarningAndLogout = () => {
            setShowSessionExpiredAlert(true);
            // Attendez un délai après l'alerte avant de déconnecter et rediriger
            setTimeout(logoutAndRedirect, logoutDelay);
        };
    
        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimer);
            clearTimeout(warningTimer);
            updateExpirationTime();
            // Réinitialiser l'avertissement et préparer la déconnexion
            inactivityTimer = setTimeout(showWarningAndLogout, warningTimeout);
        };
    
        window.addEventListener('click', resetInactivityTimer);
        window.addEventListener('keypress', resetInactivityTimer);
        // Initialisez le timer pour avertir et déconnecter
        inactivityTimer = setTimeout(showWarningAndLogout, warningTimeout);
    
        return () => {
            window.removeEventListener('click', resetInactivityTimer);
            window.removeEventListener('keypress', resetInactivityTimer);
            clearTimeout(inactivityTimer);
            clearTimeout(warningTimer);
        };
    }, [navigate]);
    

        // Ajoutez une fonction handleAlertClick pour gérer le clic sur le bouton de l'alerte
        const handleAlertClick = () => {
            console.log("Alerte ouverte");
            logoutUser(); // Déconnectez l'utilisateur
            navigate('/login'); // Redirigez l'utilisateur vers la page de connexion
        };

    const logoutUser = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('pseudo');
        localStorage.removeItem('expirationTime');
        localStorage.removeItem('login_count');
        setIsAuthenticated(false);
    };

    const registerUser = async (userData) => {
        try {
            const response = await axios.post(`${apiUrl}/database/register`, userData);
            if (response.data.message === "User registered successfully!") {
                setUserId(response.data.uniqueId);
                localStorage.setItem('userId', response.data.uniqueId);

                setIsAuthenticated(true);
            }
            // Gérer d'autres cas si nécessaire
        } catch (error) {
            console.error("Erreur lors de l'enregistrement de l'utilisateur:", error);
        }
    };

    const loginUser = async (credentials) => {
        try {
            const response = await axios.post(`${apiUrl}/database/login`, credentials);
            if (response.data.message === "Login successful") {
                setUserId(response.data.user_id);
                localStorage.setItem('userId', response.data.user_id); // Stocke l'ID utilisateur
                localStorage.setItem('pseudo', response.data.pseudo); // Stocke le pseudo de l'utilisateur
                localStorage.setItem("login_count", response.data.login_count); // Stocke le nombre de connexions de l'utilisateur")

                setIsAuthenticated(true);
                // const expirationTime = new Date(new Date().getTime() + 300000); // 300 secondes plus tard
                // localStorage.setItem('expirationTime', expirationTime.toISOString());
                // Redirige l'utilisateur vers la page /home
                navigate('/home');
            } else {
                // Gérer le cas où les informations d'identification sont incorrectes
                throw new Error(response.data.message);
                // Gérer le cas où les informations d'identification sont incorrectes
            }
        } catch (error) {
            if (error.response) {
                // La requête a été faite et le serveur a répondu avec un statut hors de la plage 2xx
                console.error("Erreur lors de la connexion:", error.response.data);
                throw new Error(error.response.data.message || "Une erreur est survenue lors de la tentative de connexion.");
            } else if (error.request) {
                // La requête a été faite mais aucune réponse n'a été reçue
                console.error("Erreur de réseau:", error.request);
                throw new Error("Erreur de réseau. Veuillez vérifier votre connexion.");
            } else {
                // Quelque chose s'est mal passé lors de la création de la requête
                console.error("Erreur:", error.message);
                throw new Error("Une erreur est survenue. Veuillez réessayer.");
            }
        }
    };

    return {
        isAuthenticated,
        showSessionExpiredAlert,
        setShowSessionExpiredAlert,
        loginUser,  // Ajoutez cette ligne pour rendre la fonction loginUser accessible
        logoutUser,
        registerUser,  // Assurez-vous que cette fonction est également retournée si vous avez besoin d'y accéder en dehors
        handleAlertClick,  // Ajoutez cette ligne
        setIsAuthenticated,
        setUserId,
    };
};
