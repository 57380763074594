import React, { Suspense, useState, useEffect, useCallback, useMemo, useRef, useLayoutEffect, createContext, useContext } from 'react';
import myImage from './images/sample-file-xlsx.PNG';
import { useAuth } from './Auth';
import { CreditsProvider } from './CreditsContext';
import { AppProvider, useAppContext, SessionProvider } from './AppContext';
import TopNav from './TopNav';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate, useLocation, Switch } from 'react-router-dom';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// import { Rnd } from 'react-rnd';
import { ResizableBox, Resizable } from 'react-resizable';
import { stripePromise } from './Stripe';
import 'react-resizable/css/styles.css'; // Importez le CSS pour les styles par défaut
import { NavigationProvider } from './NavigationContext';
import { useHistoryStack } from './NavigationContext';
import { throttle } from 'lodash';
import BuyCredits from './BuyCredits';
import {Container, TextField} from '@mui/material';
import FileUploader from './FileUploader'; 
import SessionExpiredModal from './SessionExpiredModal';
import AdminLogin from './AdminLogin'; 
import AdminInterface from './AdminInterface'; // interface d'administration
import { AuthProvider } from './AuthContext'; 
import { useAuthAdmin } from './AuthContext'; // Importe le hook useAuth
const UserDetailsForm = React.lazy(() => import('./UserDetailsForm'));
const NgramComponent = React.lazy(() => import('./NgramComponent'));
const StatisticsComponent = React.lazy(() => import('./StatisticsComponent'));
const UrlInputForm = React.lazy(() => import('./UrlInputForm'));
const ChatComponent = React.lazy(() => import('./ChatComponent'));
const SummaryComponent = React.lazy(() => import('./SummaryComponent'));
const OpenAIKeyInput = React.lazy(() => import('./OpenAIKeyInput'));
const Dashboard = React.lazy(() => import('./Dashboard'));
const WelcomeMessage = React.lazy(() => import('./WelcomeMessage'));
const Footer = React.lazy(() => import('./Footer'));
const Blog = React.lazy(() => import('./Blog'));
const LoginForm = React.lazy(() => import('./LoginForm'));
const BlogPost = React.lazy(() => import('./BlogPost'));
const Tutorial = React.lazy(() => import('./pages/Tutorial'));



const ProtectedRoute = ({ children }) => {
  const { isAuth } = useAuthAdmin();
  
  if (!isAuth) {
    // Redirige vers la page de connexion si l'utilisateur n'est pas authentifié
    return <Navigate to="/admin" replace />;
  }
  
  return children;
};



const apiUrl = process.env.REACT_APP_API_URL
// const apiUrl = 'http://localhost:8080'

// Définir les constantes pour les valeurs par défaut
const DEFAULT_NUM_NGRAMS = 40;
const DEFAULT_SORT_CRITERION = 'tfidfScore';
const DEFAULT_NGRAM_TYPE = 'all_ngrams';


// Création du contexte pour partager la hauteur
const LeftBoxHeightContext = createContext();


function App() {

  const {
    isAuthenticated,
    userEmail,
    isReturningUser,
    getAuthCode,
    errorMessage,
    pseudo,
    logoutUser,
    setIsAuthenticated,
    setPseudo,
    showSessionExpiredAlert,
    setShowSessionExpiredAlert,
  } = useAuth();

  const loginCount = parseInt(localStorage.getItem("login_count"));
  const navigate = useNavigate();
  const [urls, setUrls] = useState('');
  const [failedUrls, setFailedUrls] = useState([]);
  const [unprocessedUrls, setUnprocessedUrls] = useState([]);
  const [filteredWordCounts, setFilteredWordCounts] = useState([]);
  const allUserUrls = urls.split('\n').filter(url => url.trim() !== '');
  const [searchQuery, setSearchQuery] = useState('');
  const [userChoice, setUserChoice] = useState(null); // 'urls' ou 'query' ou null
  const [successfulUrls, setSuccessfulUrls] = useState([]);
  const [isResetting, setIsResetting] = useState(false);
  const [chatbotResponse, setChatbotResponse] = useState('');
  const [editorContent, setEditorContent] = useState('');

  // État pour stocker le pays sélectionné et la clé d'API
  const [selectedCountry, setSelectedCountry] = useState("France");
  const [apiKey, setApiKey] = useState("");
  const [summaries, setSummaries] = useState([]);
  const [displayStrings, setDisplayStrings] = useState([]);

  // Liste des domaines Google pour chaque pays
  const googleDomains = {
    "France": "google.fr",
    "United States": "google.com",
    "United Kingdom": "google.co.uk",
    "Spain": "google.es",
    "Italy": "google.it",
    "Germany": "google.de",
    "Portugal": "google.pt",
  };
  // const [inputType, setInputType] = useState('urls'); // 'urls' ou 'query'
  const [selectedUrl, setSelectedUrl] = useState('all'); // Par défaut, toutes les URLs sont sélectionnées

  const [selectedUrls, setSelectedUrls] = useState([]); // Par défaut, toutes les URLs sont sélectionnées

  const [urlArray, setUrlArray] = useState([]);
  const [showLeftBox, setShowLeftBox] = useState(true);

  function DashboardButton() {
    const navigate = useNavigate();
    const location = useLocation();


    const isOnDashboard = location.pathname === '/dashboard';

    const handleDashboardClick = () => {
      if (isOnDashboard) {
        // Si sur le tableau de bord, revenir à l'URL précédente
        navigate(-1);

      } else {
        // Sinon, aller au tableau de bord
        navigate('/dashboard');

      }
    };

    return (
      <button className="return" onClick={handleDashboardClick}>
        <span className="material-symbols-outlined icon">
          {isOnDashboard ? 'undo' : 'dashboard'}
        </span>
        <span style={{ textAlign: 'center', flex: 1 }}>
          {isOnDashboard ? 'Return' : 'Dashboard'}
        </span>
      </button>
    );
  }

  const [leftBoxSize, setLeftBoxSize] = useState({ width: 700, height: 1500 });
  const containerRef = useRef(null);
  const [isLarge, setIsLarge] = useState(false);

  const onLeftResize = throttle((event, { size }) => {
    window.requestAnimationFrame(() => {
      setLeftBoxSize(size);
      checkIfLarge(size.width);
    }, 100);
  });

  const checkIfLarge = (width) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      setIsLarge(width / containerWidth > 0.8);
    }
  };

  useLayoutEffect(() => {
    checkIfLarge(leftBoxSize.width);
  }, [leftBoxSize.width]);

  const containerStyle = {
    display: 'flex',
    flexDirection: isLarge ? 'column' : 'row',
    alignItems: 'stretch', // pour étirer les enfants à la même hauteur

  };

  const leftBoxStyle = {
    marginRight: isLarge ? '0px' : '30px', // Pas de marge quand isLarge est true
    width: leftBoxSize.width + 'px',
    height: leftBoxSize.height + 'px',
  };

  const maxWidth = containerRef.current
    ? containerRef.current.getBoundingClientRect().width - (isLarge ? 0 : 30)
    : Infinity;
  const minWidth = 300;

  function ErrorModal({ isVisible, message, onClose }) {
    if (!isVisible) return null;
    return (
      <div className="modal-overlay">
        <div className="modal-content-content">
          <p>{message}</p>
          <button  style={{ marginTop: '25px', marginRight: '25px' }}><Link to="/buy-credits">Buy credits</Link></button>
          <button className="close-button" onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }


  const [wordCountsSuccessfulUrls, setWordCountsSuccessfulUrls] = useState([]);

  const actualSelectedUrl = useMemo(() => {
    return selectedUrl === 'all' || selectedUrl.includes('all') ? successfulUrls : selectedUrl;
  }, [selectedUrl, successfulUrls]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [executionTime, setExecutionTime] = useState(null);
  const [numNgrams, setNumNgrams] = useState(DEFAULT_NUM_NGRAMS);  // Par défaut, affichez 50 N-Grams
  // const [excludeWords, setExcludeWords] = useState('');
  const [sortCriterion, setSortCriterion] = useState(DEFAULT_SORT_CRITERION); // Par défaut, triez par score TF-IDF
  const [isStatsExpanded, setIsStatsExpanded] = useState(false);
  const [stats, setStats] = useState(null);
  const [rawNgrams, setRawNgrams] = useState({ all_ngrams: [], unigrams: [], bigrams: [], trigrams: [] });
  const [displayedNgramsFromComponent, setDisplayedNgramsFromComponent] = useState([]);

  const [selectedNgramType, setSelectedNgramType] = useState(DEFAULT_NGRAM_TYPE); // 'all_ngrams', 'unigrams', 'bigrams', 'trigrams'

  const [urlDataList, setUrlDataList] = useState([]);

  const [newAllUrlsPresenceRateData, setAllUrlsPresenceRateData] = useState([]);
  const [newAiContentPresenceRateData, setAiContentPresenceRateData] = useState([]);
  const [currentSessionSuccessfulUrls, setCurrentSessionSuccessfulUrls] = useState([]);

  const [filteredNgrams, setFilteredNgrams] = useState([]);  // Initialisez comme un tableau vide
  const propsForNgramComponent = useMemo(() => ({
    filteredNgrams,
    numNgrams,
    setNumNgrams,
    sortCriterion,
    setSortCriterion,
    selectedNgramType,
    setSelectedNgramType,
    newAiContentPresenceRateData,
    newAllUrlsPresenceRateData,
  }), [filteredNgrams, numNgrams, sortCriterion, selectedNgramType]);

  const [successfulUrlData, setSuccessfulUrlData] = useState([]);

  const [isExtractNgramsClicked, setIsExtractNgramsClicked] = useState(false);

  const [showSidebar, setShowSidebar] = useState(false);

  const [showSummaries, setShowSummaries] = useState(false); // État pour afficher ou non les résumés

  const [failedUrlsWithStatus, setFailedUrlsWithStatus] = useState([]);

  const [meaningfulSuccessfulUrls, setMeaningfulSuccessfulUrls] = useState([]);

  // Déclarez excludedUrls comme un état
  const [excludedUrls, setExcludedUrls] = useState([]);

  const [ngramsDataParent, setNgramsDataParent] = useState(null);

  const [totalWordsRaw, setTotalWordsRaw] = useState(null);

  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [credits, setCredits] = useState('');
  const updateCredits = (newCredits) => {
    setCredits(newCredits);
  };

  const handleSignUpClick = () => {
    setShowSignUpForm(true);
    setShowLoginForm(false); // Cachez le formulaire de connexion lors de l'inscription
  };

  const handleLoginClick = () => {
    setShowLoginForm(true);
    setShowSignUpForm(false); // Cachez le formulaire d'inscription lors de la connexion

  };

  const handleTotalWordsRaw = (value) => {
    setTotalWordsRaw(value);
  };

  const updateNgramsData = (newData) => {
    setNgramsDataParent(newData);
  };


  useEffect(() => {
    // Mettez à jour excludedUrls chaque fois que successfulUrls ou meaningfulSuccessfulUrls change
    const newExcludedUrls = successfulUrls.filter(url => !meaningfulSuccessfulUrls.includes(url));
    setExcludedUrls(newExcludedUrls);
  }, [successfulUrls, meaningfulSuccessfulUrls]);

  const handleShowSummaries = async () => {

    // Définissez un état pour indiquer que les résumés sont en cours de génération
    setLoading(true);
    setShowSummaries(true);

    try {
      setSummaries();
    } catch (error) {
      console.error("Erreur lors de la récupération des N-Grams:", error.response);

      const errorMessage = error.response ? error.response.data.message : error.message;

      if (allUserUrls.length === 1) {
        // Si une seule URL a été soumise
        setErrors([...errors, `Erreur avec l'URL ${allUserUrls[0]}: ${errorMessage}`]);
      } else {
        // Pour plusieurs URLs, vous pouvez ajouter chaque URL échouée à failedUrls
        setFailedUrls([...failedUrls, { url: allUserUrls[0], errorCode: errorMessage }]);
      }
    }
    // Réinitialisez l'état de chargement
    setLoading(false);
  };


  const propsForAuthComponent = useMemo(() => ({
    getAuthCode
  }), [getAuthCode]);

  const handleGoBack = () => {
    setUserChoice(null);
  };

  const [keyword, setKeyword] = useState('');

  const [emptyContentUrls, setEmptyContentUrls] = useState([]);

  // const labels = useMemo(() => stats?.word_counts?.map(item => item.url) || [], [stats]);
  const data = useMemo(() => stats?.word_counts?.map(item => item.count) || [], [stats]);

  useEffect(() => {
    // Mettez à jour filteredData ici chaque fois que selectedUrl change
    // Vous pouvez également mettre à jour d'autres états liés au graphique ici
  }, [selectedUrl]);

  const filteredData = selectedUrl === 'all' ? stats?.word_counts || [] : stats?.word_counts?.filter(item => selectedUrl.includes(item.url)) || [];

  const counts = filteredData.map(item => item.count);
  const total = counts.reduce((acc, val) => acc + val, 0);
  const avg = total / counts.length;

  const urlNumbers = urlArray.map((_, index) => index + 1);

  const [filteredUrl, setFilteredUrl] = useState([]);

  const handleUrlSelected = (url) => {
    setSelectedUrl(url === 'Toutes les URLs' ? 'all' : url);
  };

  useEffect(() => {
    if (selectedUrl === 'all') {
      // Récupérez toutes les données nécessaires ici
      setFilteredUrl(stats?.word_counts || []);
    } else {
      setFilteredUrl(stats?.word_counts?.filter(item => selectedUrl.includes(item.url)) || []);
    }
  }, [selectedUrl, stats]);


  const [wordCounts, setWordCounts] = useState([]);


  useEffect(() => {
    let wordCountsToUse = [];

    if (selectedUrls.length === 0) {
      // Si aucun URL n'est sélectionné, utilisez tous les comptages de mots
      wordCountsToUse = stats?.word_counts?.map(item => item.count) || [];
    } else {
      // Sinon, filtrez les comptages de mots pour les URLs sélectionnées
      wordCountsToUse = stats?.word_counts?.filter(item => selectedUrls.includes(item.url)).map(item => item.count) || [];
    }

    setFilteredWordCounts(wordCountsToUse);
  }, [selectedUrls, stats]);

  const toggleStats = useCallback(() => {
    setIsStatsExpanded(prevState => !prevState);
  }, []);

  const propsForStatisticsComponent = useMemo(() => ({
    stats,
    isStatsExpanded,
    toggleStats,
    successfulUrls, // Mettez à jour cette ligne
    failedUrls, // Ajout de la nouvelle prop
    unprocessedUrls, // Ajout de la nouvelle prop
    excludedUrls,
    emptyContentUrls,
    errors,
    failedUrlsWithStatus,
    hasUrls: allUserUrls.length > 0,
    displayStrings
  }), [stats, isStatsExpanded, toggleStats, meaningfulSuccessfulUrls, failedUrls, unprocessedUrls, excludedUrls, emptyContentUrls, errors, displayStrings]);

  useEffect(() => {
    let ngramsToDisplay = [];
    if (selectedNgramType === 'all_ngrams') {
      ngramsToDisplay = [...rawNgrams.all_ngrams, ...rawNgrams.unigrams, ...rawNgrams.bigrams, ...rawNgrams.trigrams];
    } else {
      ngramsToDisplay = rawNgrams[selectedNgramType];
    }

    // Limite le nombre de N-Grams à afficher en fonction de la valeur de numNgrams
    const limitedNgrams = ngramsToDisplay.slice(0, numNgrams);
    setFilteredNgrams(limitedNgrams);
  }, [rawNgrams, selectedNgramType, numNgrams]);


  const handleSubmit = useCallback(async () => {
    setLoading(true);
    setShowLeftBox(true);
    const startTime = performance.now();
    setUrlDataList([]); // Réinitialiser la liste des données d'URL
    let requestData = {};

    if (userChoice === 'urls') {
      const validUrls = urls.split('\n').filter(url => url.trim() !== '');
      requestData = { urls: validUrls, input_type: 'urls' };
    } else {
      requestData = { query: searchQuery, selected_domain: selectedCountry };
      try {
        // Récupérer le userId dans le localStorage
        const userId = localStorage.getItem('userId');
        const response = await axios.post(`${apiUrl}/ngrams/get-urls`, { ...requestData, userId });

        if (!response.data.success) {
          throw new Error(response.data.error || "Unknown error during URL retrieval.");
        }
        setUrls(response.data.urls.join('\n')); // Mettez à jour l'état des URLs avec les URLs récupérées
        requestData = { urls: response.data.urls, input_type: 'urls' }; // Mettez à jour requestData pour la prochaine requête

        setFailedUrls(failedUrls);
        // Convertir les tableaux en ensembles pour faciliter la manipulation
        const allUrlsSet = new Set(urls.split('\n').filter(url => url.trim() !== '')); // Utilisez 'setUrls' pour obtenir toutes les URLs
        const successfulUrlsSet = new Set(successfulUrls);
        const failedUrlsSet = new Set(failedUrls);

        // Soustraire les URLs réussies et échouées de toutes les URLs pour obtenir les URLs non traitées
        const unprocessedUrlsSet = new Set([...allUrlsSet].filter(x => !successfulUrlsSet.has(x) && !failedUrlsSet.has(x)));

        // Convertir l'ensemble en tableau
        const unprocessedUrlsArray = Array.from(unprocessedUrlsSet);

        // Mettre à jour l'état
        setUnprocessedUrls(unprocessedUrlsArray);
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error.message;
        setLoading(false);
        setErrors([...errors, "Une erreur est survenue lors de la récupération des N-Grams."]);
        setModalErrorMessage(error.response?.data?.error || error.message);
        setIsModalVisible(true);
        setShowLeftBox(false);  // Masquez LeftBoxHeightContext.Provider

        return;
      }
    }

    try {
      // Réinitialiser la liste des URLs réussies pour la session courante
      setCurrentSessionSuccessfulUrls([]);
      // Récupérer le userId dans le localStorage
      const userId = localStorage.getItem('userId');
      const response = await axios.post(`${apiUrl}/ngrams/extract-ngrams`, { ...requestData, userId });

      // Filtrer les URLs réussies avec du contenu non vide
      const filteredResults = response.data.results
        .filter(res => res.status === "success" && res.cleaned_content && res.cleaned_content.split(' ').length > 0);

      const successfulUrls = filteredResults.map(res => res.url);
      const wordCountsSuccessfulUrls = filteredResults.map(res => res.word_count);

      // Mettre à jour les états avec les URLs et les comptages de mots filtrés
      setSuccessfulUrls(successfulUrls);
      setWordCountsSuccessfulUrls(wordCountsSuccessfulUrls);
      setFailedUrlsWithStatus(response.data.failed_urls_with_status);
      // Mettre à jour la liste des URLs réussies pour la session courante
      setCurrentSessionSuccessfulUrls(successfulUrls);

      const urlDataArray = successfulUrls.reduce((acc, url) => {
        const urlData = response.data.results.find(res => res.url === url);
        if (urlData) {
          acc.push(urlData);
        }
        return acc;
      }, []);

      setSuccessfulUrlData(urlDataArray);

      const failedUrls = response.data.results.filter(res => res.status === "failed").map(res => ({
        url: res.url,
        errorCode: res.http_status_code // Assurez-vous que votre API retourne cette clé
      }));
      setFailedUrlsWithStatus(failedUrls);

      // Convertir les tableaux en ensembles pour faciliter la manipulation
      const allUrlsSet = new Set(urls.split('\n').filter(url => url.trim() !== '')); // Utilisez 'setUrls' pour obtenir toutes les URLs
      const successfulUrlsSet = new Set(successfulUrls);
      const failedUrlsSet = new Set(failedUrls);

      // Soustraire les URLs réussies et échouées de toutes les URLs pour obtenir les URLs non traitées
      const unprocessedUrlsSet = new Set([...allUrlsSet].filter(x => !successfulUrlsSet.has(x) && !failedUrlsSet.has(x)));

      // Convertir l'ensemble en tableau
      const unprocessedUrlsArray = Array.from(unprocessedUrlsSet);

      // Mettre à jour l'état
      setUnprocessedUrls(unprocessedUrlsArray);

      // Mettez à jour 'urls' avec les URLs réussies
      setSuccessfulUrls(successfulUrls);

      const emptyUrls = successfulUrls.filter(url => {
        const urlData = response.data.results.find(res => res.url === url);
        return urlData && (!urlData.cleaned_content || urlData.cleaned_content.split(' ').length === 0);
      });

      // Mettre à jour l'état
      setEmptyContentUrls(emptyUrls);
      setRawNgrams({
        all_ngrams: response.data.all_ngrams,
        unigrams: response.data.unigrams,
        bigrams: response.data.bigrams,
        trigrams: response.data.trigrams
      });
      successfulUrls.forEach(url => {
        const urlData = response.data.results.find(res => res.url === url);
        if (urlData) {
          setUrlDataList(prevList => [...prevList, urlData]);
        }
      });

      setSuccessfulUrls(prevState => {
        // Votre logique pour filtrer prevState et retourner un nouvel état
        const meaningfulUrls = prevState.filter(url => {
          const urlData = response.data.results.find(res => res.url === url);
          return urlData && urlData.cleaned_content && urlData.cleaned_content.split(' ').length > 0;
        });
        return meaningfulUrls;
      });

      setStats(response.data.stats);
      const wordCounts = stats?.word_counts?.map(item => item.count) || [];
      setFilteredWordCounts(wordCounts);
      setUrlArray(successfulUrls);
      setErrors(failedUrls);
    } catch (error) {
      console.error("Erreur lors de la récupération des N-Grams:", error.response?.data?.error || error.message);
      setErrors([...errors, "Une erreur est survenue lors de la récupération des N-Grams."]);
      setModalErrorMessage(error.response?.data?.error || error.message);
      setIsModalVisible(true);
      setShowLeftBox(false);  // Masquez LeftBoxHeightContext.Provider
    }

    const endTime = performance.now();
    const elapsedTime = ((endTime - startTime) / 1000).toFixed(3);
    setExecutionTime(elapsedTime);
    setLoading(false);
    // Mettez à jour l'état pour indiquer que le bouton a été cliqué
    setIsExtractNgramsClicked(prevState => !prevState);
    setIsExtractNgramsClicked(true); // Mettez à jour l'état pour indiquer que le bouton a été cliqué
  }, [urls, searchQuery, errors, userChoice, selectedCountry, apiKey]);

  useEffect(() => {
  }, [isExtractNgramsClicked]);

  useEffect(() => {
    if (userChoice === 'query') {
      setKeyword(searchQuery);
    }
  }, [searchQuery, userChoice]);

  const propsForUrlInputForm = useMemo(() => ({
    urls,
    setUrls,
    searchQuery,
    setSearchQuery,
    handleSubmit,
    loading,
    apiKey,
    setApiKey,
    selectedCountry,
    setSelectedCountry,
    googleDomains,
  }), [urls, searchQuery, loading, apiKey, selectedCountry, googleDomains]);


  const EXCEL_CELL_CHARACTER_LIMIT = 32767;

  const truncateString = (str) => {
    if (str.length > EXCEL_CELL_CHARACTER_LIMIT) {
      // Tronquer la chaîne et ajouter une indication que le contenu a été tronqué
      return str.slice(0, EXCEL_CELL_CHARACTER_LIMIT) + '... [Contenu tronqué]';
    }
    return str;
  };

  const downloadResults = () => {
    const workbook = new ExcelJS.Workbook();

    // Création de la feuille pour les N-Grams
    const nGramSheet = workbook.addWorksheet('N-Grams');
    nGramSheet.columns = [
      { header: 'N-Gram', key: 'ngram' },
      { header: 'Score TF-IDF', key: 'score' },
      { header: "Nombre d'URLs", key: 'urlCount' },
      { header: 'Occurrence', key: 'occurrence' },
      { header: 'Taux de présence', key: 'presenceRate' }
    ];

    // Ajout des données N-Grams
    const { unigrams, bigrams, trigrams } = rawNgrams;
    const allRelevantNgrams = [].concat(unigrams, bigrams, trigrams);
    const sortedNgrams = allRelevantNgrams.sort((a, b) => b[1] - a[1]).map(arr => arr.slice(0, 5));
    sortedNgrams.forEach(ngram => {
      nGramSheet.addRow({
        ngram: ngram[0],
        score: ngram[1],
        urlCount: ngram[2],
        occurrence: ngram[3],
        presenceRate: ngram[4]
      });
    });

    // Création de la feuille pour les données URL
    const urlSheet = workbook.addWorksheet('URL Data');
    urlSheet.columns = [
      { header: 'URL', key: 'url' },
      { header: 'Title', key: 'title' },
      { header: 'Meta Description', key: 'metaDescription' },
      { header: 'Balises Hn', key: 'headings' },
      { header: 'Contenu principal', key: 'content' },
      { header: 'Nombre de mots', key: 'wordCount' }
    ];

    // Ajout des données URL
    successfulUrlData.forEach(data => {
      if (data) {
        const headingsString = Array.isArray(data.headings)
          ? data.headings.map(h => `H${h[0].length}: ${h[1]}`).join('; ')
          : 'N/A';
        urlSheet.addRow({
          url: truncateString(data.url || ''),
          title: truncateString(data.title || ''),
          metaDescription: truncateString(data.meta_description || ''),
          headings: truncateString(headingsString),
          content: truncateString(data.content),
          wordCount: data.word_count || ''
        });
      }
    });

    // Sauvegarde du fichier
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer]), "data.xlsx");
    });
  };

  const handleUserRegistered = () => {
    setShowSignUpForm(false);
    navigate('/home');
    //setShowLoginForm(true); // Affichez le formulaire de connexion après l'inscription
  };



  const handleReset = () => {
    // Réinitialiser tous les états ici
    setUrls('');
    setSearchQuery('');
    setUserChoice(null);
    setSuccessfulUrls([]);
    setSelectedCountry("France");
    setApiKey("");
    setLoading(false);
    setErrors([]);
    setExecutionTime(null);
    setNumNgrams(DEFAULT_NUM_NGRAMS);
    setSortCriterion(DEFAULT_SORT_CRITERION);
    setIsStatsExpanded(false);
    setStats(null);
    setRawNgrams({ all_ngrams: [], unigrams: [], bigrams: [], trigrams: [] });
    setFilteredNgrams([]);
    setSuccessfulUrlData([]);
    setIsExtractNgramsClicked(false);
    setShowSummaries(false);
    setKeyword('');
    setUrlArray([]);
    setSelectedUrl('all');
    setIsResetting(true);
    setChatbotResponse('');
    setEditorContent('');
    sessionStorage.removeItem('chatbotResponse');
    sessionStorage.removeItem('editorContent');
    sessionStorage.removeItem('lastData');
    sessionStorage.removeItem('keyword');
    setTimeout(() => setIsResetting(false), 0);
  };

  const handleLoginSuccess = (userData) => {
    // Logique pour définir l'utilisateur comme authentifié
    setIsAuthenticated(true);
    setShowLoginForm(false);
    navigate('/home');
  };

  const handleLogoutClick = () => {
    logoutUser();  // Appelle la fonction logoutUser lorsque le bouton est cliqué
    navigate('/login');
  };

  const handleReconnect = () => {
    setShowLoginForm(true);
  };

  const location = useLocation();
useEffect(() => {
  const isPublicRoute = location.pathname === '/sign-up' ||
    location.pathname.startsWith('/blog') ||
    location.pathname.startsWith('/tuto') ||
    location.pathname.includes('legal-notices') ||
    location.pathname.includes('pricing') ||
    location.pathname.includes('politique-gestion-cookies');

  if (!localStorage.getItem('userId') && !isPublicRoute && location.pathname !== '/login') {
    navigate('/login');
  }
}, [location, navigate]);



  const handleUrlListClick = async () => {

    setUserChoice('urls');
    const userId = localStorage.getItem('userId');
    if (!userId) {
      navigate('/login');
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(`${apiUrl}/database/check-user`, { userId });
      if (response.data.redirect) {
        navigate(response.data.redirect);
        setIsAuthorized(false);
      } else {
        setIsAuthorized(true); // Mettre à jour l'état pour indiquer que l'utilisateur est autorisé
        navigate('/url-list'); // Naviguer vers /url-list si l'utilisateur est autorisé

      }
    } catch (error) {
      setIsAuthorized(false);
      if (error.response && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        console.error('There was an error!', error.response);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryClick = async () => {
    setUserChoice('query');
    const userId = localStorage.getItem('userId');
    if (!userId) {
      navigate('/login');
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(`${apiUrl}/database/check-user`, { userId });
      if (response.data.redirect) {
        navigate(response.data.redirect);
        setIsAuthorized(false);
      } else {
        setIsAuthorized(true); // Mettre à jour l'état pour indiquer que l'utilisateur est autorisé
        navigate('/query'); // Naviguer vers /query si l'utilisateur est autorisé
      }
    } catch (error) {
      setIsAuthorized(false);
      if (error.response && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        console.error('There was an error!', error.response);
      }
    } finally {
      setIsLoading(false);
    }
  };
  function PaymentSuccess() {
    return (
      <div className="main-content" style={{ flexGrow: 1 }}>
        <h1>Help Content</h1>
        <div className='slogan'>By AI, for humans !</div>
        <p>Congratulations, you now have lots of credits for using the Help Content application. Make a good use of it !</p>
      </div>
    );
  }

  function PaymentFailed() {
    return (
      <div className="main-content" style={{ flexGrow: 1 }}>
        <h1>Help Content</h1>
        <div className='slogan'>By AI, for humans !</div>
        <p>Payment failed. Please try again.</p>
      </div>
    );
  }

  function LegalNotices() {
    return (
      <div className="main-content" style={{ flexGrow: 1 }}>
        <h1>Help Content</h1>
        <div className='slogan'>By AI, for humans !</div>
        <h2>Legal Information</h2>
        <div className='legal-notices' style={{ textAlign: 'left' }}>
          <h3>Editing and copywriting</h3>
          <ul>
            <li>Julien Gourdon
            </li>
            <li>Publication manager : Julien Gourdon</li>
            <li>Email : julien.gourdon@outlook.com</li>
            <li>Website : https://julien-gourdon.fr</li>
          </ul>
          <h3>Hosting</h3>
          <h4>Frontend part</h4>
          <ul>
            <li>Address : Vercel Inc, 440 N Barranca Ave #4133, Covina, CA 91723</li>
            <li>Contact : https://vercel.com/contact</li>
            <li>Website : https://vercel.com/</li>
          </ul>
          <h4>Backend part</h4>
          <ul>
            <li>Address : Google Ireland Limited, a company incorporated in Ireland (Company registration number: 368047/VAT number: IE6388047V), Gordon House, Barrow Street, Dublin 4, Ireland</li>
            <li>Contact : https://cloud.google.com/contact</li>
            <li>Website : https://cloud.google.com//</li>
          </ul>
          <h3>Rights and duties of users</h3>
          <p>All users of the application https://www.help-content.ai/ acknowledges having the skills and means necessary to access and use this website.</p>
          <p>They are considered to have accepted the application of French laws currently in force. They are required to comply with the French Data Protection Act, violation of which may result in criminal penalties. All application content is the property of its author and creator.</p>
          <p>https://www.help-content.ai/ is an application protected by intellectual property legislation. Any reproduction or representation made without the agreement of the creator and editor constitutes an infringement punishable by law. Only reading and use for personal purposes are authorized.</p>
          <p>Reproduction of all or part of the https://www.help-content.ai/ application on any medium whatsoever is strictly forbidden unless authorized by the creator and editor. All rights reserved.</p>
          <p>Links to external sites are provided for information purposes only and https://www.help-content.ai/ cannot be held responsible for them under any circumstances.</p>
          <Footer />
        </div>
      </div>
    );
  }

  function CookiePolicy() {
    return (
      <div className="main-content" style={{ flexGrow: 1 }}>
        <h1>Help Content</h1>
        <div className='slogan'>By AI, for humans !</div>
        <h2>Cookie Policy</h2>
        <div className='cookie-policy' style={{ textAlign: 'left' }}>
          <h3>Cookies</h3>
          <p>Cookies are very small files stored on your computer by your browser. They are used to collect information about your browsing, such as the pages you have visited, the links you have followed and the time you have spent on the site. If you do not wish to receive cookies, you can deactivate the cookie storage option on your computer by modifying your browser settings.</p>
          <p>There are two types of cookies: persistent cookies and session cookies. Persistent cookies are stored on your device for a set period of time, even after you have left the site. Session cookies are deleted when you close your browser.</p>
          <p>This site uses persistent cookies to recognize you when you return to the site.</p>
          <h3>Google Analytics</h3>
          <p>We use Google Analytics, a web analytics service provided by Google Inc. ("Google"). Google Analytics uses "cookies" to help the website analyze how users use the site. The information generated by the cookie about your use of our website (including your truncated IP address) is transmitted to a server controlled by Google. This server is located in the United States. Your data will be stored in this country.Google will use this information about your use of our website to generate reports on website activity for website operators and to provide other services relating to website and Internet usage.Google may also share such information with third parties insofar as this is legally required and/or insofar as third parties process the data on Google's behalf.</p>
          <p>Detailed information on how Google uses your data can be found in the Google privacy statement: www.google.com/policies/privacy/<br />
            You can deactivate Google Analytics using a browser add-on if you do not want your data to be included in this website analysis. This browser add-on can be downloaded from https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=fr.</p>

          <h3>Cookie management</h3>
          <p>You can change your preferences with regard to Google Analytics cookies by clicking on the pop-up window at the bottom left of this site, and then clicking on the "I choose" tab in the window that appears on the screen. You then have the choice of leaving the default choice "Accept Google Analytics cookies", or modifying it by sliding the slider to the left to refuse Google Analytics cookies.</p>
        </div>
        <Footer />
      </div>
    );
  }

  function Pricing() {
    return (
      <div className="main-content" style={{ flexGrow: 1 }}>
        <h1>Help Content</h1>
        <div className='slogan'>By AI, for humans !</div>
        <h2>Pricing and policy security</h2>
        <div className='pricing' style={{ textAlign: 'left' }}>
          <h3>Pricing</h3>
          <p>Help Content is a paying application. However, you get 30 free credits when you create your account, which allows you to test the app for free. After that, you need to buy credits by subscribing to either the Basic Pack (10 euros for 130 credits), the Standard Pack (20 euros for 270 credits) or the Expert Pack (40 euros for 580 credits).</p>
          <p>Credit purchases are debited immediately, with no right of withdrawal.</p>
          <p>The cost of each of the application's features is as follows:</p>
          <ul>
            <li>URL scraping (from a list of URLs or a Google query): 0.1 credit</li>
            <li>URLs summary + extra analysis: 0.1 credit per URL + 2 credits for the additional analysis (if 10 URLs are to be summarised, the cost in credits will be 3 credits)</li>
            <li>Editorial brief: 5 credits per brief</li>
            <li>SEO article: 6 credits per H2 paragraph (an article containing 4 H2 paragraphs will cost 24 credits)</li>
          </ul>
          <p>The cost in credits for each of the application's functionalities may change at the discretion of the application owner, depending on the cost of the APIs used.</p>

          <h3>Policy security</h3>
          <p>Help Content uses the Stripe payment system. Your credit card details are therefore not stored on the application's servers. They are stored on Stripe's servers, which are highly secure. You can find out more about Stripe's security policy by clicking on the following link: https://stripe.com/fr-fr/privacy.</p>
          <p>Help Content is a web application that uses the Google Cloud Platform to host its backend. The application is hosted on Google Cloud Platform servers located in the European Union. You can find out more about Google Cloud Platform's security policy by clicking on the following link: https://cloud.google.com/security/privacy.</p>
          <p>Help Content uses the Vercel platform to host its frontend. The application is hosted on Vercel servers located in the European Union. You can find out more about Vercel's security policy by clicking on the following link: https://vercel.com/security.</p>

        </div>
        <Footer />
      </div>
    );
  }

  function Upload() {
    return (
      <div className="main-content" style={{ flexGrow: 1 }}>
        <h1>Help Content</h1>
        <div className='slogan'>By AI, for humans !</div>
        <br />
          <h3>Import your content for internal linking recommendations</h3>
          <br />
          <div style={{ textAlign: 'left', marginLeft: '20%', marginRight: '20%' }} >
          <p>Upload your content in a .xlsx file to get internal linking recommendations. The file should contain the content of your website, with one URL per line. The file must contain 3 columns with the following headers:
          <ol>
            <li>
            Column 1: 'project_name' (the name is free but we recommend you choose the name of your website, or the name of a category on your website)</li>
            <li>
            Column 2: 'keyword' (the name is free, but we recommend you choose the title tag of your URL)</li>
            <li>
            Column 4: 'content' (this should be the main content of your URL)</li>
          </ol>
          <p>You are only authorized to import 1000 items of content at a time (i.e. your xlsx file must not exceed 1000 lines).</p>
          </p>
          </div>
          <br />
          <FileUploader />
          <br />
          <h4 style={{alignItems: 'flex-start', marginLeft: '20%', marginRight: '20%'}}
          >Three-column xlsx file structure expected</h4>
          <img src={myImage} alt="Description" />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        <Footer />
      </div>
    );
  }

  // Fonction pour extraire les phrases interrogatives
const extractInterrogativeSentences = (content) => {
  // Traiter les sauts de ligne comme fin de phrase et séparer les phrases.
  const lines = content.split(/[\r\n]+/).map(line => line.trim()).filter(line => line);
  const questionRegex = /\?$/;

  // Extraire et retourner les phrases interrogatives de chaque ligne.
  return lines.reduce((acc, line) => {
    // Appliquer le regex de question à la ligne actuelle.
    if (questionRegex.test(line)) {
      acc.push(line);
    }
    return acc;
  }, []);
};

  const buildDisplayStrings = () => {
    const newDisplayStrings = successfulUrlData.map(data => {
      if (data) {
        let displayString = `- ${data.url}\n`;
  
        // Ajout du title
        if (data.title) {
          displayString += `  - Title: ${data.title}\n`;
        } else {
          displayString += "  - No title available\n";
        }
  
        // Ajout de la metadescription
        if (data.meta_description) {
          displayString += `  - Meta description: ${data.meta_description}\n\n`;
        } else {
          displayString += "  - No meta description available\n\n";
        }
  
        // Ajout des headings
        if (Array.isArray(data.headings) && data.headings.length) {
          data.headings.forEach(h => {
            displayString += `  - H${h[0].length}: ${h[1]}\n`;
          });
        } else {
          displayString += "  - No headings available\n";
        }
  
        // Extraction et ajout des phrases interrogatives
        const interrogativeSentences = extractInterrogativeSentences(data.content);
        displayString += "\nList of questions\n";
        if (interrogativeSentences.length > 0) {
          interrogativeSentences.forEach(sentence => {
            displayString += `  - Q: ${sentence.trim()}\n`;
          });
        } else {
          displayString += "  - No interrogative sentences available\n";
        }
  
        return displayString;
      }
  
      return '';
    });
  
    setDisplayStrings(newDisplayStrings);
  };
  
// Appeler buildDisplayStrings dès que successfulUrlData change
React.useEffect(() => {
  buildDisplayStrings();
}, [successfulUrlData]);

const handleAlertClick = () => {
  setShowSessionExpiredAlert(false); // Ferme le modal
  navigate('/login'); // Redirige vers la page de login
};




  /* Rendu de l'application */
  return (
    <AuthProvider>
    <CreditsProvider>
      <NavigationProvider>
      <> 
        <div className="App">       
            <SessionExpiredModal isOpen={showSessionExpiredAlert} onClose={handleAlertClick} />               
          <TopNav  isAuthenticated={isAuthenticated} handleLogoutClick={handleLogoutClick}  handleLoginClick={handleLoginClick} handleSignUpClick={handleSignUpClick} />
          <div className={`sidebar ${showSidebar ? 'show' : ''}`} style={{ flexGrow: 1, overflow: 'auto' }}>
            <div className="top-buttons">
              <Link to="/home" className="return" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => {
                handleReset();
              }}>
                <div className="returnHome">
                  <span className="material-symbols-outlined icon">home</span>
                  <span style={{ textAlign: 'center', flex: 1, marginLeft: '23px' }}>Home</span>
                </div>
              </Link>
              <DashboardButton className="dashboard-button" />
            </div>

            {/* Affichez l'input pour la clé d'API SERPAPI uniquement si userChoice est 'query' 
            {userChoice === 'query' && (
              <>
                <input className='api-key-input'
                  type="password"
                  placeholder="🔑 Enter your SERPAPI API key..."
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
                <p className='p-serpapi'>You can obtain a free SERPAPI API key by clicking <a href="https://serpapi.com/dashboard" target="_blank" rel="noopener noreferrer">on this link</a></p>
              </>
            )}
            */}
            {/* Affichez l'input pour la clé d'API OpenAI uniquement si isExtractNgramsClicked est vrai 
            {isExtractNgramsClicked && <OpenAIKeyInput openAIKey={openAIKey} setOpenAIKey={setOpenAIKey} />}
            */}
            
            <nav className='author'>Developed with ❤️ by Help Content</nav>
          </div>
          
          <Suspense fallback={<div style={{ marginTop: '40px'}}>Chargement...</div>}>
          <Routes>

            <Route path="/url-list" element={
              <>

                {isAuthorized ? (

                  <div className="main-content" style={{ flexGrow: 1 }}>

                    <ErrorModal
                      isVisible={isModalVisible}
                      message={modalErrorMessage}
                      onClose={() => setIsModalVisible(false)}
                    />
                    <h1>Help Content</h1>
                    <div className='slogan'>By AI, for humans !</div>

                    {/* Affichage conditionnel basé sur l'état isAuthenticated */}
                    {!isAuthenticated ? (
                      <div className='login'>
                        {/* Afficher les formulaires de connexion et d'inscription si non authentifié */}
                        {showSignUpForm && <UserDetailsForm onUserRegistered={handleUserRegistered} />}
                        {showLoginForm && <LoginForm onLoginSuccess={handleLoginSuccess} />}
                      </div>
                    ) : (
                      <>
                        {/* Affichez le message de bienvenue ici, seulement si l'utilisateur est authentifié */}
                        {isAuthenticated && isReturningUser !== null && (
                          <WelcomeMessage credits={credits} setCredits={setCredits} isLoading={isLoading} setIsLoading={setIsLoading} />
                        )}
                        {!userChoice ? (
                          <>
                            <p className='intro'>Before starting the automatic generation of your articles, the application needs to retrieve existing web content to give context to the AI. How do you want to proceed ?</p>
                            <div className="user-choice">
                              <button onClick={handleUrlListClick}>Enter a list of URLs</button>
                              <button onClick={handleQueryClick}>Make a query to Google</button>
                            </div>

                          </>
                        ) : (
                          <>
                            <UrlInputForm {...propsForUrlInputForm} userChoice={userChoice} />
                            <BeatLoader loading={loading} className='loader-extract-ngrams' />
                            {executionTime && (
                              <>
                                {successfulUrls.length > 0 ? (
                                  <p className="successMessage">N-Grams successfully extracted in {executionTime} seconds</p>
                                ) : (
                                  <p>No URLs could be processed successfully 🙁. Please provide a new list of URLs. {failedUrls.errorCode}</p>
                                )}
                                {showLeftBox && (
                                  <div ref={containerRef} style={containerStyle}>
                                    <Resizable
                                      width={leftBoxSize.width}
                                      height={leftBoxSize.height}
                                      onResize={onLeftResize}
                                      maxConstraints={[maxWidth, Infinity]}
                                      minConstraints={[minWidth, 100]}
                                    >
                                      <div style={leftBoxStyle}>
                                        <div>
                                          <StatisticsComponent {...propsForStatisticsComponent} />
                                          <div className='ngramComponent'>
                                            <NgramComponent
                                              {...propsForNgramComponent}
                                              onNgramsFiltered={setDisplayedNgramsFromComponent}
                                              successfulUrls={successfulUrls}
                                              selectedUrl={actualSelectedUrl}
                                              setSelectedUrl={setSelectedUrl}
                                              onUrlSelected={handleUrlSelected}
                                              data={data}
                                              allUserUrls={allUserUrls}
                                              handleReset={handleReset}
                                              ngramsData={ngramsDataParent}
                                              allUrlsPresenceRateData={newAllUrlsPresenceRateData}
                                              aiContentPresenceRateData={newAiContentPresenceRateData}
                                              wordCountsSuccessfulUrls={wordCountsSuccessfulUrls}
                                              totalWordsRaw={totalWordsRaw}
                                            />
                                            <button onClick={downloadResults} className='downloadResult'>Download data</button>
                                            {/* <UrlDataTable data={successfulUrlData} />/}
            {/* Nouveau bouton pour déclencher la génération des résumés */}
                                          </div>
                                        </div>
                                      </div>
                                    </Resizable>
                                    {/* Intégration des composants qui étaient dans RightBox */}
                                    <div style={{ flexGrow: 1 }}>
                                      <SummaryComponent
                                        data={successfulUrlData}
                                        updateCredits={updateCredits} credits={credits}
                                      />
                                      <ChatComponent
                                        keyword={keyword}
                                        setKeyword={setKeyword}
                                        resetChat={isExtractNgramsClicked}
                                        urlDataList={urlDataList}
                                        rawNgrams={rawNgrams}
                                        updateNgramsData={updateNgramsData}
                                        onTotalWordsRaw={handleTotalWordsRaw}
                                        credits={credits}
                                      />

                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                              <br />
                              <br />
                              <div>
                                {/*
                    <p>Utilisez le bouton ci-dessous pour uploader un fichier xlsx :</p>
                      <FileUploader />
                    */}
                              
                    </div>
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                            <Footer />
                          </>
                        )}
                      </>
                    )}
                  </div>


                ) : (
                  <div className="main-content" style={{ flexGrow: 1 }}>
                    <h1>Help Content</h1>
                    <div className='slogan'>By AI, for humans !</div>
                    <div>
                      <p>You are not authorized to access this page. Please log in to continue using the Help Content application</p>
                      <br></br>                     

                      <button>
                        <Link to="/login" style={{ textDecoration: 'none', color: 'black' }}>Log in</Link>
                      </button>                    
                      </div>
                  </div>
                )}

              </>

            }
            />
            <Route path="/query" element={
              <>

              {isAuthorized ? (

                <div className="main-content" style={{ flexGrow: 1 }}>

                  <ErrorModal
                    isVisible={isModalVisible}
                    message={modalErrorMessage}
                    onClose={() => setIsModalVisible(false)}
                  />
                  <h1>Help Content</h1>
                  <div className='slogan'>By AI, for humans !</div>

                  {/* Affichage conditionnel basé sur l'état isAuthenticated */}
                  {!isAuthenticated ? (
                    <div className='login'>
                      {/* Afficher les formulaires de connexion et d'inscription si non authentifié */}
                      {showSignUpForm && <UserDetailsForm onUserRegistered={handleUserRegistered} />}
                      {showLoginForm && <LoginForm onLoginSuccess={handleLoginSuccess} />}
                    </div>
                  ) : (
                    <>
                      {/* Affichez le message de bienvenue ici, seulement si l'utilisateur est authentifié */}
                      {isAuthenticated && isReturningUser !== null && (
                        <WelcomeMessage credits={credits} setCredits={setCredits} isLoading={isLoading} setIsLoading={setIsLoading} />
                      )}
                      { !userChoice  ? (
                        <>

                            <p className='intro'>Before starting the automatic generation of your articles, the application needs to retrieve existing web content to give context to the AI. How do you want to proceed ?</p>
                            <div className="user-choice">
                              <button onClick={handleUrlListClick}>Enter a list of URLs</button>
                              <button onClick={handleQueryClick}>Make a query to Google</button>
                            </div>

                          </>
                        ) : (
                          <>
                            <UrlInputForm {...propsForUrlInputForm} userChoice={userChoice} />
                            <BeatLoader loading={loading} className='loader-extract-ngrams' />
                            {executionTime && (
                              <>
                                {successfulUrls.length > 0 ? (
                                  <p className="successMessage">N-Grams successfully extracted in {executionTime} seconds</p>
                                ) : (
                                  <p>No URLs could be processed successfully 🙁. Please provide a new list of URLs. {failedUrls.errorCode}</p>
                                )}
                                {showLeftBox && (
                                  <div ref={containerRef} style={containerStyle}>
                                    <Resizable
                                      width={leftBoxSize.width}
                                      height={leftBoxSize.height}
                                      onResize={onLeftResize}
                                      maxConstraints={[maxWidth, Infinity]}
                                      minConstraints={[minWidth, 100]}
                                    >
                                      <div style={leftBoxStyle}>
                                        <div>
                                          <StatisticsComponent {...propsForStatisticsComponent} />
                                          <div className='ngramComponent'>
                                            <NgramComponent
                                              {...propsForNgramComponent}
                                              onNgramsFiltered={setDisplayedNgramsFromComponent}
                                              successfulUrls={successfulUrls}
                                              selectedUrl={actualSelectedUrl}
                                              setSelectedUrl={setSelectedUrl}
                                              onUrlSelected={handleUrlSelected}
                                              data={data}
                                              allUserUrls={allUserUrls}
                                              handleReset={handleReset}
                                              ngramsData={ngramsDataParent}
                                              allUrlsPresenceRateData={newAllUrlsPresenceRateData}
                                              aiContentPresenceRateData={newAiContentPresenceRateData}
                                              wordCountsSuccessfulUrls={wordCountsSuccessfulUrls}
                                              totalWordsRaw={totalWordsRaw}
                                            />
                                            <button onClick={downloadResults} className='downloadResult'>Download data</button>
                                            {/* <UrlDataTable data={successfulUrlData} />/}
            {/* Nouveau bouton pour déclencher la génération des résumés */}
                                          </div>
                                        </div>
                                      </div>
                                    </Resizable>
                                    {/* Intégration des composants qui étaient dans RightBox */}
                                    <div style={{ flexGrow: 1 }}>
                                      <SummaryComponent
                                        data={successfulUrlData}
                                      />
                                      <ChatComponent
                                        keyword={keyword}
                                        setKeyword={setKeyword}
                                        resetChat={isExtractNgramsClicked}
                                        urlDataList={urlDataList}
                                        rawNgrams={rawNgrams}
                                        updateNgramsData={updateNgramsData}
                                        onTotalWordsRaw={handleTotalWordsRaw}
                                      />
                                    </div>
                                  </div>

                                )}
                              </>
                            )}
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                            <Footer />
                          </>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="main-content" style={{ flexGrow: 1 }}>
                    <h1>Help Content</h1>
                    <div className='slogan'>By AI, for humans !</div>
                    <div>
                      <p>You are not authorized to access this page. Please log in to continue using the Help Content application</p>
                      <br></br>                     

                      <button>
                        <Link to="/login" style={{ textDecoration: 'none', color: 'black' }}>Log in</Link>
                      </button>                    
                      </div>
                  </div>
                )}
              </>
            } />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/login" element={
              <>
                <div className="main-content" style={{ flexGrow: 1 }}>
                  <h1>Help Content</h1>
                  <div className='slogan'>By AI, for humans !</div>
                  <LoginForm onLoginSuccess={handleLoginSuccess} />
                  <Footer />
                </div>
              </>
            } />
            <Route path="/sign-up" element={
              <>
                <div className="main-content" style={{ flexGrow: 1 }}>
                  <h1>Help Content</h1>
                  <div className='slogan'>By AI, for humans !</div>
                  <UserDetailsForm onUserRegistered={handleUserRegistered} />
                </div>
              </>
            } />
            <Route path="/dashboard" element={
              <>
                <div className="main-content" style={{ flexGrow: 1 }}>

                  <h1>Help Content</h1>
                  <div className='slogan'>By AI, for humans !</div>
                  <div className='welcome'>
                    <p>Hello {localStorage.getItem('pseudo')} !</p>
                  </div>
                  <Dashboard />
                  <Footer />
                </div>
              </>
            } />
            <Route path="/home" element={
              <div className="main-content" style={{ flexGrow: 1 }}>

                <h1>Help Content</h1>
                <div className='slogan'>By AI, for humans !</div>

                    {/* Affichez le message de bienvenue ici, seulement si l'utilisateur est authentifié */}
                    {isAuthenticated && isReturningUser !== null && (
                      <>
                      <WelcomeMessage
                        credits={credits}
                        setCredits={setCredits}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                      />                      
                        <p className='intro'>Before starting the automatic generation of your articles, the application needs to retrieve existing web content to give context to the AI. How do you want to proceed ?</p>
                        <div className="user-choice">
                          <button onClick={handleUrlListClick}>Enter a list of URLs</button>
                          <button onClick={handleQueryClick}>Make a query to Google</button>
                        </div>
                        </>
    )}
                <br></br>
                            {/* Insérer une vidéo youtube ici : https://www.youtube.com/watch?v=Gk5O-cXDhh0 */}
                        
                    {!isAuthenticated && (
                      
                      <div className='login'>
                        {/* Afficher les formulaires de connexion et d'inscription si non authentifié */}
                        {showLoginForm && <LoginForm onLoginSuccess={handleLoginSuccess} />}
                      </div>
                    )}
                    <br />
                    <br />

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                <Footer />
              </div>
            } 
            />

            <Route path="/" element={
              <div className="main-content" style={{ flexGrow: 1 }}>

                <h1>Help Content</h1>
                <div className='slogan'>By AI, for humans !</div>

                    {/* Affichez le message de bienvenue ici, seulement si l'utilisateur */}
                    {isAuthenticated && isReturningUser !== null && (
                      <>
                      <WelcomeMessage
                        credits={credits}
                        setCredits={setCredits}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                      />
                       <p className='intro'>Before starting the automatic generation of your articles, the application needs to retrieve existing web content to give context to the AI. How do you want to proceed ?</p>
                        <div className="user-choice">
                          <button onClick={handleUrlListClick}>Enter a list of URLs</button>
                          <button onClick={handleQueryClick}>Make a query to Google</button>
                        </div>
                        </>
    )}
                <br></br>
                            {/* Insérer une vidéo youtube ici : https://www.youtube.com/watch?v=Gk5O-cXDhh0 */}
                        
                    {!isAuthenticated && (
                      
                      <div className='login'>
                        {/* Afficher les formulaires de connexion et d'inscription si non authentifié */}
                        {showLoginForm && <LoginForm onLoginSuccess={handleLoginSuccess} />}
                      </div>
                      
                    )}
                    <div>
                    <br />
                    <br />
                    {/*
      <p>Utilisez le bouton ci-dessous pour uploader un fichier xlsx :</p>
      <FileUploader />
       Vous pouvez ajouter d'autres composants ou contenu ici. */}
    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                <Footer />
              </div>
            } />
            <Route path="/paiement-reussi" element={<PaymentSuccess />} />
            <Route path="/paiement-annule" element={<PaymentFailed />} />
            <Route path="/legal-notices" element={<LegalNotices />} />
            <Route path="/politique-gestion-cookies" element={<CookiePolicy />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/buy-credits" element={<BuyCredits />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/adminInterface" element={<ProtectedRoute></ProtectedRoute>} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/tutorial" element={<Tutorial />} />
          </Routes>
          </Suspense>
          
        </div >
      </>
      </NavigationProvider>
    </CreditsProvider>
    </AuthProvider>
  );
}

export default App;
