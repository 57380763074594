import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';
import './App.css';

const TopNav = ({ isAuthenticated, handleLogoutClick, handleLoginClick, handleSignUpClick }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const navigationLinks = (
        <>
            <Link to="/tutorial" className="nav-link">Tuto</Link>
            <Link to="/blog" className="nav-link">Blog</Link>
            <Link to="/buy-credits" className="nav-link">Pricing</Link>
            {isAuthenticated ? (
                <button onClick={handleLogoutClick} className="nav-link">Log out</button>
            ) : (
                <>
                    <Link to="/login" className="nav-link" onClick={handleLoginClick}>Login</Link>
                    <Link to="/sign-up" className="nav-link" onClick={handleSignUpClick}>Sign Up</Link>
                </>
            )}
        </>
    );

    return (
        <AppBar position="static" 
        sx={{ 
            display: 'flex',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            backgroundColor: '#333333',
            color: 'white',
            padding: '5px 0',
            boxShadow: '0 2px 4px rgba(0, 0, 0, .5)',
            zIndex: '1001'
              }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <div className="hcLogo" style={{
                fontSize: '21px',
                color: 'white',
                fontFamily: 'math',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '50%',
                border: '1px solid rgb(0, 123, 255)',
                backgroundColor: 'rgb(0 123 255 / 0%)',
                fontWeight: 'bold',
                flexDirection: 'column',
            }}>
                HC
            </div>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, ml: 'auto' }}>
                    <Link to="/tutorial" className="nav-link">Tuto</Link>
                    <Link to="/blog" className="nav-link">Blog</Link>
                    <Link to="/buy-credits" className="nav-link">Pricing</Link>
                    {isAuthenticated ? (
                    <Link to="/log-out" className="nav-link" onClick={handleLogoutClick}>Log out</Link>
                    ) : (
                        <>
                            <Link to="/login" className="nav-link">Login</Link>
                            <Link to="/sign-up" className="nav-link">Sign Up</Link>
                        </>
                    )}
                </Box>
                <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    sx={{ display: { xs: 'flex', sm: 'none' }, fontSize: '2rem' }} // Ajustez ici pour la taille
                >
                    <MenuIcon sx={{ fontSize: '2.3rem' }} /> {/* Agrandir l'icône */}
                </IconButton>
                <Drawer
                    anchor='right'
                    open={isDrawerOpen}
                    onClose={toggleDrawer(false)}
                    sx={{ '& .MuiDrawer-paper': { backgroundColor: 'white' } }} // Fond gris clair
                >
                    <List sx={{ color: 'black', fontWeight: "bold" }}>
                        <ListItem component={Link} to="/blog" onClick={toggleDrawer(false)} style={{ color: 'black', fontWeight: "bold" }}>
                            <ListItemText primary="Blog" />
                        </ListItem>
                        <ListItem component={Link} to="/buy-credits" onClick={toggleDrawer(false)} style={{ color: 'black', fontWeight: "bold" }}>
                            <ListItemText primary="Pricing" />
                        </ListItem>
                        {isAuthenticated ? (

                        <ListItem component={Link} to="/login" onClick={handleLoginClick} style={{ color: 'black', fontWeight: "bold" }}>
                        <ListItemText primary="Login" />
                        </ListItem>
                        ) : (
                            <>

                                <ListItem component={Link} to="/sign-up" onClick={handleSignUpClick} style={{ color: 'black', fontWeight: "bold" }}>
                                    <ListItemText primary="Sign Up" />
                                </ListItem>
                            </>
                        )}
                    </List>
                </Drawer>

            </Toolbar>
        </AppBar>
    );
};

export default TopNav;
