import React, { useState, useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Importer les styles CSS de Quill
import './App.css';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_URL
// const apiUrl = 'http://localhost:8080';

const AdminInterface = () => {
  const [formData, setFormData] = useState({
    title: '',
    metaDescription: '',
    metaLanguage: '',
    language: 'fr',
    canonicalTag: '',
    breadcrumb: '',
    structuredData: '',
    publishDate: '',
    readTime: '',
    author: '',
    heroImage: null,
    slug: '',
    content: ''
  });
  const [heroImageFile, setHeroImageFile] = useState(null);
  const [localImageFiles, setLocalImageFiles] = useState([]);


  function handleHeroImageChange(event) {
    const file = event.target.files[0];
    if (file) {
      setHeroImageFile(file); // Stockez le fichier pour un envoi ultérieur
    }
  }

  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const fileInputRef = useRef(null); // Ajout d'une référence pour l'input de fichier


  useEffect(() => {
    if (editorRef.current) {
      const toolbarOptions = [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link'],
        [{ 'image': 'imageHandler' }] // Utiliser un identifiant de gestionnaire personnalisé
      ];

      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions
        }
      });

      // Définition du gestionnaire personnalisé
      quill.getModule('toolbar').addHandler('imageHandler', () => {
        fileInputRef.current.click(); // Déclencher le clic sur l'input de fichier
      });

      quillRef.current = quill;
    }
  }, []);


  function uploadHeroImage(event) {
    const file = event.target.files[0];
    if (!file) {
        console.error('Aucun fichier sélectionné');
        return;
    }

    // Création d'un nouveau FormData pour l'upload
    const formData = new FormData();
    formData.append('image', file);

    // Correction : Utilisez directement formData.slug pour construire l'URL d'upload
    const uploadURL = `${apiUrl}/upload_hero_image/${formData.slug}`;

    // Envoi de la requête d'upload
    axios.post(uploadURL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => {
      if(response.data && response.data.url) {
          setFormData(prevState => ({
              ...prevState,
              heroImage: response.data.url
          }));
      } else {
          console.error('URL non reçue de l\'API');
      }
  })
    .catch(error => {
        console.error('Erreur lors de l\'upload de l\'image héro:', error);
    });
}

// Ajouter cette fonction au champ input pour l'image héro dans votre formulaire
  

  function uploadImage(file, isLocalImage = false) {
    const formData = new FormData();
    formData.append('image', file);

    // La logique pour gérer l'URL change selon si c'est une hero image ou une image locale
    const uploadURL = isLocalImage ? 
      `${apiUrl}/database/upload_image/${formData.slug}` : // URL pour image locale
      `${apiUrl}/database/upload_hero_image/${formData.slug}`; // URL pour hero image

    axios.post(uploadURL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      if (response.data.url) {
        if (isLocalImage) {
          const range = quillRef.current.getSelection(true);
          quillRef.current.insertEmbed(range.index, 'image', response.data.url);
        } else {
          setFormData(prevState => ({
            ...prevState,
            heroImage: response.data.url
          }));
        }
      }
    })
    .catch(error => {
      console.error('Erreur lors de l\'upload de l\'image:', error);
    });
  }

  // Cette fonction est appelée quand l'utilisateur sélectionne une image à uploader
  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const imageURL = URL.createObjectURL(file);
        const range = quillRef.current.getSelection(true);
        quillRef.current.insertEmbed(range.index, 'image', imageURL);
        setLocalImageFiles(prevFiles => [...prevFiles, file]);
      }
    };
    fileInputRef.current = input; // Utiliser la référence pour stocker l'input
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Créer un FormData pour le contenu de l'article et l'image héro
    const submissionFormData = new FormData();
    // Ajoutez d'autres champs de formData ici
    submissionFormData.append('content', quillRef.current.root.innerHTML);
    if (heroImageFile) {
      submissionFormData.append('heroImage', heroImageFile);
    }

    // Ajouter chaque fichier d'image locale au FormData
    localImageFiles.forEach((file, index) => {
      submissionFormData.append(`localImage${index}`, file);
    });

    // Ajouter tous les autres éléments de formData, à l'exception de "content" et "heroImage

    for (const key in formData) {
      if (key !== 'content' && key !== 'heroImage') {
        submissionFormData.append(key, formData[key]);
      }
    }


    try {
      const response = await axios.post(`${apiUrl}/database/blog_articles_with_hero_image`, submissionFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // Traitez la réponse...
      console.log('Article publié:', response.data);

    } catch (error) {
      console.error('Erreur lors de la publication de l\'article', error);
    }
  };
  

  return (
    <div className="App"> 
            <h1>Help Content</h1>
            <div className='slogan'>By AI, for humans !</div>
    <div className="main-content" style={{ flexGrow: 1, maxWidth: '1200px', marginLeft: '80px' }}>
    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={selectLocalImage} />


                <form onSubmit={handleSubmit}  className="form-container">

                <h2>Interface d'Administration du Blog</h2>
                <br />
                  <br />
                <div className="form-group">
                <label>Title:</label>
                <input name="title" type="text" value={formData.title} onChange={handleChange} />
                </div>
                <div className="form-group">
                <label>Meta Description:</label>
                <input name="metaDescription" type="text" value={formData.metaDescription} onChange={handleChange} />
                </div>

                <div className="form-group">
                <label>Meta Language:</label>
                <input name="metaLanguage" type="text" value={formData.metaLanguage} onChange={handleChange} />
                </div>

                <div className="form-group">
                <label>Langue de l'article:</label>
                <select name="language" value={formData.language} onChange={handleChange}>
                  <option value="fr">Français</option>
                  <option value="us">Anglais (US)</option>
                  <option value="es">Espagnol</option>
                  <option value="de">Allemand</option>
                </select>
              </div>

              <div className="form-group">
                <label>Balise Canonique:</label>
                <input name="canonicalTag" type="text" value={formData.canonicalTag} onChange={handleChange} />
              </div>

                <div className="form-group">
                <label>Breadcrumb:</label>
                <input name="breadcrumb" type="text" value={formData.breadcrumb} onChange={handleChange} />
                </div>

                <div className="form-group">
                <label>Structured Data:</label>
                <textarea name="structuredData" value={formData.structuredData} onChange={handleChange} />
                </div>

                <div className="form-group">

                <label>Date de Publication:</label>
                <input name="publishDate" type="date" value={formData.publishDate} onChange={handleChange} />
                </div>

                <div className="form-group">

                <label>Temps de Lecture (min):</label>
                <input name="readTime" type="number" value={formData.readTime} onChange={handleChange} />
                </div>
                
                <div className="form-group">
                <label>Auteur:</label>
                <input name="author" type="text" value={formData.author} onChange={handleChange} />
                </div>

                <div className="form-group">
                <label>Hero Image:</label>
                <input name="heroImage" type="file" onChange={(event) => setHeroImageFile(event.target.files[0])} />
                </div>

                <div className="form-group">
                <label>Slug de l'URL:</label>
                <input name="slug" type="text" value={formData.slug} onChange={handleChange} />
                </div>

                
                <label>Contenu de l'Article:</label>
                <div ref={editorRef} style={{height: "400px"}}></div>
                <br />
                <br />
                <button type="submit">Publier l'Article</button>
            </form>
            <br />
            <br />
            <br />
            <br />
            <br />
    </div>
    </div>
  );
};

export default AdminInterface;
