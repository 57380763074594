import React, { useState, useEffect } from 'react';
import './BuyCredits.css'; // Assurez-vous d'importer votre fichier CSS
import './App.css'; // Assurez-vous d'importer votre fichier CSS
import { stripePromise } from './Stripe';
import axios from 'axios';

// Récupérer l'URL de votre API
const apiUrl = process.env.REACT_APP_API_URL
// const apiUrl = 'http://localhost:8080/';

function BuyCredits() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userId, setUserId] = useState(null);


    useEffect(() => {
        const userIdFromStorage = localStorage.getItem('userId');
        if (userIdFromStorage) {
            setIsAuthenticated(true);
            setUserId(userIdFromStorage); // Mettre à jour le state
        }
    }, []);


    // Fonctions pour gérer les clics sur les boutons d'achat
    const handleBuyBasic = async () => {
        if (isAuthenticated) {
            // Supposons que vous stockez l'userId dans le state de votre composant après l'authentification
            const userId = localStorage.getItem('userId');
            // Supposons également que vous avez un identifiant de prix pour votre pack Basic
            const priceId = 'price_1OtVBHJRzxFbwVBP3qK8RD8I';

            try {
                const stripe = await stripePromise;
                // Appel à votre API pour créer une session de paiement
                const response = await axios.post(`${apiUrl}/database/create-checkout-session`, {
                    userId: userId,
                    priceId: priceId,  // Assurez-vous que ce soit l'identifiant de prix correct pour le pack Basic
                });
                const sessionId = response.data.id;
                // Rediriger l'utilisateur vers le Checkout de Stripe
                const result = await stripe.redirectToCheckout({ sessionId });
                if (result.error) {
                    console.error(result.error.message);
                    alert("Erreur lors de la redirection vers le paiement.");
                }
            } catch (error) {
                console.error('Erreur lors de la création de la session de paiement', error);
                alert("Impossible de procéder au paiement. Veuillez réessayer.");
            }
        } else {
            alert('Vous devez être connecté pour effectuer cet achat.');
        }
    };


    const handleBuyStandard = async () => {
        if (isAuthenticated) {
            // Supposons que vous stockez l'userId dans le state de votre composant après l'authentification
            const userId = localStorage.getItem('userId');
            // Supposons également que vous avez un identifiant de prix pour votre pack Basic
            const priceId = 'price_1OtVEIJRzxFbwVBP8Msedckg';

            try {
                const stripe = await stripePromise;
                // Appel à votre API pour créer une session de paiement
                const response = await axios.post(`${apiUrl}/database/create-checkout-session`, {
                    userId: userId,
                    priceId: priceId,  // Assurez-vous que ce soit l'identifiant de prix correct pour le pack Basic
                });
                const sessionId = response.data.id;
                // Rediriger l'utilisateur vers le Checkout de Stripe
                const result = await stripe.redirectToCheckout({ sessionId });
                if (result.error) {
                    console.error(result.error.message);
                    alert("Erreur lors de la redirection vers le paiement.");
                }
            } catch (error) {
                console.error('Erreur lors de la création de la session de paiement', error);
                alert("Impossible de procéder au paiement. Veuillez réessayer.");
            }
        } else {
            alert('Vous devez être connecté pour effectuer cet achat.');
        }
    };

    const handleBuyExpert = async () => {
        if (isAuthenticated) {
            // Supposons que vous stockez l'userId dans le state de votre composant après l'authentification
            const userId = localStorage.getItem('userId');
            // Supposons également que vous avez un identifiant de prix pour votre pack Basic
            const priceId = 'price_1OtXvvJRzxFbwVBPWbgi1RI6';

            try {
                const stripe = await stripePromise;
                // Appel à votre API pour créer une session de paiement
                const response = await axios.post(`${apiUrl}/database/create-checkout-session`, {
                    userId: userId,
                    priceId: priceId,  // Assurez-vous que ce soit l'identifiant de prix correct pour le pack Basic
                });
                const sessionId = response.data.id;
                // Rediriger l'utilisateur vers le Checkout de Stripe
                const result = await stripe.redirectToCheckout({ sessionId });
                if (result.error) {
                    console.error(result.error.message);
                    alert("Erreur lors de la redirection vers le paiement.");
                }
            } catch (error) {
                console.error('Erreur lors de la création de la session de paiement', error);
                alert("Impossible de procéder au paiement. Veuillez réessayer.");
            }
        } else {
            alert('Vous devez être connecté pour effectuer cet achat.');
        }
    };


    return (

        <div className="buy-credits-container">
            <div className="credit-option basic">
                <h3>Pack Basic</h3>
                <p>130 credits</p>
                <p>€10</p>
                <button onClick={handleBuyBasic} style={{ fontSize: '16px', padding: '10px 30px 10px 30px' }}>Buy Basic</button>
                <div className="credit-option-basic-description" style={{ textAlign: 'left', marginLeft: '20px', marginTop: '40px' }}>
                    <p><strong>Up to 5 SEO articles</strong></p>
                    <p>To test application capabilities !</p>
                    <p>provides a list of keywords ranked by relevance, taken directly from content that ranks on Google Page 1</p>
                    <p>Insert links in your articles to boost your existing articles</p>
                    <p>Analyzes the questions answered by the content that ranks on Google Page 1, and provides added value </p>
                </div>
            </div>
            <div className="credit-option standard">
                <h3>Pack Standard</h3>
                <p>270 credits</p>
                <p>€20</p>
                <button onClick={handleBuyStandard} style={{ fontSize: '16px', padding: '10px 30px 10px 30px' }}>Buy Standard</button>
                <div className="credit-option-standard-description" style={{ textAlign: 'left', marginLeft: '20px', marginTop: '40px' }}>
                    <p><strong>Up to 11 SEO articles</strong></p>
                    <p>To increase your visibility in Google !</p>
                    <p>provides a list of keywords ranked by relevance, taken directly from content that ranks on Google Page 1</p>

                    <p>Insert links in your articles to boost your existing articles</p>
                    <p>Analyzes the questions answered by the content that ranks on Google Page 1, and provides added value </p></div>
            </div>
            <div className="credit-option expert">
                <h3>Pack Expert</h3>
                <p>580 credits</p>
                <p>€40</p>
                <button onClick={handleBuyExpert} style={{ fontSize: '16px', padding: '10px 30px 10px 30px' }}>Buy Expert</button>
                <div className="credit-option-standard-description" style={{ textAlign: 'left', marginLeft: '20px', marginTop: '40px' }}>
                    <p><strong>Up to 24 SEO articles</strong></p>
                    <p>To begin scaling and outperform your competitors !</p>
                    <p>provides a list of keywords ranked by relevance, taken directly from content that ranks on Google Page 1</p>
                    <p>Insert links in your articles to boost your existing articles</p>
                    <p>Analyzes the questions answered by the content that ranks on Google Page 1, and provides added value </p>

                </div>
            </div>

        </div>

    );
}

export default BuyCredits;
