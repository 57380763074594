import React, { createContext, useState, useContext, useEffect } from 'react';

// Création du contexte
const AppContext = createContext();

// Provider dans lequel vous englobez votre application
export const AppProvider = ({ children }) => {
    const [chatbotResponse, setChatbotResponse] = useState('');
    const [editorContent, setEditorContent] = useState('');
    const [isResetting, setIsResetting] = useState(false);
    const [newAiContentPresenceRateData, setNewAiContentPresenceRateData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('fr');


    const clearNewAiContentPresenceRateData = () => {
        setNewAiContentPresenceRateData([]);
    };

    // Restaurez les données de sessionStorage lors de l'initialisation
    useEffect(() => {
        if (!isResetting) {
            const savedChatbotResponse = sessionStorage.getItem('chatbotResponse');
            const savedEditorContent = sessionStorage.getItem('editorContent');
            if (savedChatbotResponse) setChatbotResponse(savedChatbotResponse);
            if (savedEditorContent) setEditorContent(savedEditorContent);
        }
    }, [isResetting]);

    // Sauvegardez dans sessionStorage lorsque les valeurs changent
    useEffect(() => {
        sessionStorage.setItem('chatbotResponse', chatbotResponse);
    }, [chatbotResponse]);

    useEffect(() => {
        sessionStorage.setItem('editorContent', editorContent);
    }, [editorContent]);

    return (
        <AppContext.Provider value={{
            chatbotResponse, setChatbotResponse, editorContent, setEditorContent, setIsResetting, newAiContentPresenceRateData,
            setNewAiContentPresenceRateData,
            clearNewAiContentPresenceRateData,
            selectedLanguage, setSelectedLanguage
        }}>
            {children}
        </AppContext.Provider>
    );
};

// Hook personnalisé pour utiliser le contexte
export const useAppContext = () => useContext(AppContext);
