import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { useAuthAdmin } from './AuthContext'; // Importe le hook useAuth


const AdminLogin = () => {
  const [pseudodo, setPseudodo] = useState('');
  const [passwordo, setPasswordo] = useState('');
  const { setIsAuth } = useAuthAdmin();

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    
// Utilisez les variables d'environnement pour vérifier les identifiants
const adminPseudo = process.env.REACT_APP_PSEUDODO;
const adminPassword = process.env.REACT_APP_PASSWORDO;

if (adminPseudo === adminPseudo && adminPassword === adminPassword) {
  setIsAuth(true);
  navigate('/adminInterface');
} else {
  alert('Pseudo ou mot de passe incorrect.');
}
};

  return (
    <div className="App"> 
        <div className="main-content" style={{ flexGrow: 1 }}>
            <h1>Help Content</h1>
                <div className='slogan'>By AI, for humans !</div>
                    <form onSubmit={handleLogin}>
                <div>
        <label>Pseudo:</label>
        <input type="text" value={pseudodo} onChange={(e) => setPseudodo(e.target.value)} />
      </div>
      <div>
        <label>Mot de passe:</label>
        <input type="password" value={passwordo} onChange={(e) => setPasswordo(e.target.value)} />
      </div>
      <button type="submit">Se connecter</button>
    </form>
    </div>
    </div>
    
  );
};

export default AdminLogin;
